import { OPEN_MODAL, CLOSE_MODAL } from './promptTypes';

const initialState = {
  promptModalOpen: false,
  promptModalMsg: '',
};

const promptReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        promptModalOpen: true,
        promptModalMsg: action.payload,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        promptModalOpen: false,
        promptModalMsg: '',
      };

    default:
      return state;
  }
};

export default promptReducer;
