import { CLEAR_QUERY_STRINGS, SET_QRY_STR_MEDIUM, SET_QRY_STR_SOURCE } from "./queryStringsTypes";

const initialState = {
  medium: "",
  source: ""
};

const queryStringReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_QRY_STR_MEDIUM:
            return ({ ...state, medium: action.payload })
        case SET_QRY_STR_SOURCE: 
            return ({ ...state, source: action.payload });
        case CLEAR_QUERY_STRINGS: 
            return ({ ...initialState });
        default: return state
    }
}

export default queryStringReducer;
