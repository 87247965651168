/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setQryStrMedium,
  setQryStrSource,
} from "./redux/queryStrings/queryStringsActions";
import { useTranslate } from "./translate";
import { RootTabsContainer } from "@afg-ikea/ikea-family-registration-module/dist";
import "./App.scss";

function App() {
  const dispatch = useDispatch();
  const i18n = useTranslate();
  const { getCurrentLanguage } = i18n;
  const mediumFromStore = useSelector(
    ({ queryStrings }) => queryStrings.medium
  );
  const sourceFromStore = useSelector(
    ({ queryStrings }) => queryStrings.source
  );
  useEffect(() => {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const medium = parameters.get("utm_medium");
    const source = parameters.get("utm_source");
    dispatch(setQryStrMedium(medium));
    dispatch(setQryStrSource(source));
  }, []);

  const currLocale = document.documentElement.lang
    ? document.documentElement.lang
    : getCurrentLanguage();
  return (
    <>
      <RootTabsContainer
        medium={mediumFromStore}
        source={sourceFromStore}
        locale={currLocale}
      />
    </>
  );
}

export default App;
