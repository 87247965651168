import { CLEAR_QUERY_STRINGS, SET_QRY_STR_MEDIUM, SET_QRY_STR_SOURCE } from "./queryStringsTypes";

export const setQryStrMedium = (medium) => ({
  type: SET_QRY_STR_MEDIUM,
  payload: medium,
});

export const setQryStrSource = (source) => ({
  type: SET_QRY_STR_SOURCE,
  payload: source,
});

export const clearQueryStrings = () => ({
    type: CLEAR_QUERY_STRINGS
})