import { combineReducers } from 'redux';
import loaderReducer from './loader/loaderReducer';
import promptReducer from './promptModal/promptReducer';
import queryStringReducer from "./queryStrings/queryStringsReducer";

const rootReducer = combineReducers({
  loader: loaderReducer,
  promptModal: promptReducer,
  queryStrings: queryStringReducer,
});

export default rootReducer;
